const data = () => {
    const dataForm = document.querySelector(".data__form form[name='data']");
    if (dataForm) {
        const inputMinutes = dataForm.querySelector("[name='custom-minutes']");
        const inputHours = dataForm.querySelector("[name='custom-hours']");
        const inputTime = dataForm.querySelector("[name='data[hour]']");
        dataForm.addEventListener("input", function(e){
            if (e.target.name == "custom-minutes" || e.target.name == "custom-hours") {
                let minutes = inputMinutes.value == "" ? 0 : parseInt(inputMinutes.value);
                let hours = inputHours.value == "" ? 0 : parseFloat(inputHours.value) * 60;
                let summaryTime = minutes + hours;
                inputTime.value = (summaryTime / 60).toFixed(2);
            }
            if (e.target.name == "data[hour]") {
                let time = e.target.value == "" ? 0 : parseFloat(e.target.value);
                let minutes = parseInt((time * 60) % 60);
                let hours = time == "" ? 0 : parseInt(time);
                inputHours.value = hours;
                inputMinutes.value = minutes;
            }
        });
    }
}
export default data;
