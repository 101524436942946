class CustomSelect {
    constructor(element, settings = {}) {
        this.elements = {
            wrapper: element,
        };
        this.settings = {};
        Object.assign(this.settings, settings);
        this.init();
    }
    setElements() {
        const wrap = this.elements.wrapper;
        this.elements.inputWrap = wrap.querySelector(".custom-select__input");
        this.elements.input = wrap.querySelector(".custom-select__input input");

        this.elements.list = wrap.querySelector(".custom-select__list");
        this.elements.listItems = wrap.querySelectorAll(".custom-select__list-item");

        this.elements.selectWrap = wrap.querySelector(".custom-select__select");
        this.elements.select = wrap.querySelector(".custom-select__select select");

        this.elements.toggle = wrap.querySelector(".custom-select__toggle");
        this.elements.close = wrap.querySelector(".custom-select__close");
    }
    setEvents() {
        const self = this;
        this.elements.inputWrap.addEventListener("click", function(e){
            self.elements.wrapper.classList.add("open");
        });
        this.elements.toggle.addEventListener("click", function(e){
            e.stopPropagation();
            self.elements.wrapper.classList.toggle("open");
        });
        this.elements.close.addEventListener("click", function(e){
            self.elements.wrapper.classList.remove("open");
        });
        this.elements.list.addEventListener("click", function(e){
            if (e.target.dataset.hasOwnProperty("value")) {
                const selected = self.elements.select.querySelector("[selected]");
                if (selected) {
                    selected.selected = false;
                }
                self.elements.select.querySelector("[value='" + e.target.dataset.value + "']").selected = true;
                self.elements.select.value = e.target.dataset.value;
                self.elements.input.value = e.target.innerText;
                self.elements.input.placeholder = e.target.innerText;
                self.elements.wrapper.classList.remove("open");
            }
        });
        this.elements.input.addEventListener("input", function(e){
            const findText = e.target.value;
            const elements = self.elements.listItems;
            for (var i = 0; i < elements.length; i++) {
                if (elements[i].innerText.toLowerCase().indexOf(findText.toLowerCase()) == -1) {
                    elements[i].style.display = "none";
                }
                else {
                    elements[i].style.display = "block";
                }
            }
        });
        this.elements.input.addEventListener("focus", function(e){
            const findText = e.target.value;
            const elements = self.elements.listItems;
            for (var i = 0; i < elements.length; i++) {
                if (elements[i].innerText.toLowerCase().indexOf(findText.toLowerCase()) == -1) {
                    elements[i].style.display = "none";
                }
                else {
                    elements[i].style.display = "block";
                }
            }
        });
        this.elements.input.addEventListener("blur", function(e){
            if (this.value != this.placeholder) {
                this.value = this.placeholder;
            }
        });
    }
    init() {
        this.setElements();
        this.setEvents();
    }
}

export default CustomSelect;
