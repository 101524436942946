const filters = (CustomSelect) => {
    const filtersForm = document.querySelector("form[name='filter'], form[name='data']");
    if (filtersForm) {
        const serviceSelect = filtersForm.querySelector("#filter_project, #data_project");
        const projectSelect = filtersForm.querySelector("#filter_parent, #data_subproject");
        let projectOptions = false;
        if (projectSelect) {
            projectOptions = projectSelect.querySelectorAll("option");
        }
        if (serviceSelect && serviceSelect.value != "") {
            let id = serviceSelect.value;
            for (var i = 0; i < projectOptions.length; i++) {
                if(projectOptions[i].dataset.parent != id) {
                    projectOptions[i].style.display = "none";
                }
                else {
                    projectOptions[i].style = "";
                }
            }
        }
        else {
            for (var i = 0; i < projectOptions.length; i++) {
                projectOptions[i].style = "";
            }
        }
        filtersForm.addEventListener("change", function(e){
            if (serviceSelect && serviceSelect.value != "") {
                let id = serviceSelect.value;
                for (var i = 0; i < projectOptions.length; i++) {
                    if(projectOptions[i].dataset.parent != id) {
                        projectOptions[i].style.display = "none";
                    }
                    else {
                        projectOptions[i].style = "";
                    }
                }
            }
            else {
                if (projectOptions) {
                    for (var i = 0; i < projectOptions.length; i++) {
                        projectOptions[i].style = "";
                    }
                }
            }
        });
    }

    const filterMonthReport = document.querySelector(".filters-report form[name='filter']");
    const filterDateFrom = document.querySelector(".form-date__from");
    const filterDateTo = document.querySelector(".form-date__to");
    if (filterMonthReport) {
        filterMonthReport.addEventListener("submit", function(e){
            e.preventDefault();
            let now = new Date();
            let monthSelect = this.querySelector("[name='month']");
            let yearSelect = this.querySelector("[name='year']");
            let month = monthSelect.value;
            let year = yearSelect.value;
            let startDate = new Date(year, month - 1, 1);
            let endDate = new Date(year, month, 0);
            if (filterDateFrom && filterDateTo) {
                console.log(startDate.getDay(), startDate.getMonth(), startDate.getFullYear());

                filterDateFrom.querySelector("[name*='day']").value = startDate.getDate();
                filterDateFrom.querySelector("[name*='month']").value = startDate.getMonth() + 1;
                filterDateFrom.querySelector("[name*='year']").value = startDate.getFullYear();

                filterDateTo.querySelector("[name*='day']").value = endDate.getDate();
                filterDateTo.querySelector("[name*='month']").value = endDate.getMonth() + 1;
                filterDateTo.querySelector("[name*='year']").value = endDate.getFullYear();
            }
            this.submit();
        });
    }

    const customSelects = document.querySelectorAll(".custom-select");
    for (var i = 0; i < customSelects.length; i++) {
        let customSelect = new CustomSelect(customSelects[i], {blabla: false});
    }
}

export default filters;
